<template>
  <div>
    <c-card title="신규 입사자 일괄업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 적용 -->
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'userNewExcelUpload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['empNo'], // 데이터들의 키 속성값
        taskClassCd: 'USER_NEW_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
      this.excelUploadInfo.columns = [[
        {
          name: 'empNo',
          label: '사번',
          align: 'center',
          sortable: false,
          style: 'width:200px',
          required: true,
        },
        {
          name: 'userName',
          label: '이름',
          align: 'center',
          sortable: false,
          required: true,
        },
        {
          name: 'deptName',
          label: '부서',
          align: 'center',
          sortable: false,
          required: true,
        },
        {
          name: 'deptCd',
          label: '부서코드(입력X)',
          align: 'center',
          sortable: false,
          required: true,
        },
        {
          name: 'sexName',
          label: '성별',
          align: 'center',
          sortable: false,
        },
        {
          name: 'sexCd',
          label: '성별 코드(입력 X)',
          align: 'center',
          sortable: false,
        },
        {
          name: 'mobileNo',
          label: '전화번호',
          align: 'center',
          sortable: false,
        },
        {
          name: 'birthDate',
          label: '생년월일(YYYY-MM-DD)',
          align: 'center',
          sortable: false,
        },
        {
          name: 'email',
          label: '이메일',
          align: 'left',
          sortable: false,
        },
        {
          name: 'jobName',
          label: '직책',
          align: 'center',
          sortable: false,
        },
        {
          name: 'jobCd',
          label: '직책 코드(입력 X)',
          align: 'center',
          sortable: false,
        },
      ]]
      // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '에러가 있는 데이터가 존재합니다.\n데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '적용하시겠습니까?\n(* 이미 존재하는 사번은 업로드 되지 않습니다.)',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
